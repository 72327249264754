/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import Form from '../Form';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import ConfigurationAlert from '../ConfigurationAlert';

import {
  CurrentUserContext,
} from '../../Contexts/CurrentUser';

import {
  reportTypes,
  EMPTY_FIELDS,
  DOES_NOT_EXPIRE,
} from './data';

import {
  decodeURLHash,
  isNotEmpty,
  removeFromURLHash,
  userDisplayName,
  capitalize,
  triggerHashRefresh,
  encodeURLHash,
  itemIsFunction,
  itemIsArray,
  paramsToFilters,
} from '../Utilities';

import './style.scss';
import InlineSVG from '../InlineSVG';
import { getFieldValues } from '../Form/Shared';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../legacy/io';
import { buildParamsForFastApiEndpoints } from '../RecordCache';
import { hasFeatureAccess } from '../../components/App/AccessControl';

// opens the report creator and handles any callbacks
export const openReportCreator = callback => {
  // eslint-disable-next-line camelcase
  encodeURLHash( { creating_report: true } );
  triggerHashRefresh();
  if ( isNotEmpty( callback ) && itemIsFunction( callback ) ) {
    callback();
  }
};

// closes the report creator and handles any passed in callbacks
export const closeReportCreator = callback => {
  // eslint-disable-next-line camelcase
  removeFromURLHash( 'creating_report' );
  triggerHashRefresh();
  if ( isNotEmpty( callback ) && itemIsFunction( callback ) ) {
    callback();
  }
};

// used to cancel out and handle any redirects if necessary
export const cancelReportCreator = callback => {
  if ( decodeURLHash()['return_to_reports'] ) {
    window.location.href = '#.=reporting&page=exports';
  } else {
    removeFromURLHash( 'creating_report' );
    removeFromURLHash( 'return_to_reports' );
    removeFromURLHash( 'report_id' );
    triggerHashRefresh();
    if ( isNotEmpty( callback ) && itemIsFunction( callback ) ) {
      callback();
    }
  }
};

const ReportCreator = ( {
  existingReport,
  setExistingReport,
  advanced,
  filterBuilderVariant,
} ) => {
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ fields, setFields ] = React.useState( [] );
  // eslint-disable-next-line no-unused-vars
  const [ allUsers, setAllUsers ] = React.useState( {} );
  // eslint-disable-next-line no-unused-vars
  const [ validUsers, setValidUsers ] = React.useState( {} );

  const [ updatedForm, setUpdatedForm ] = React.useState( null );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ showReportCreator, setShowReportCreator ] = React.useState( false );

  const [ existingReportRecord, setExistingReportRecord ] = React.useState( existingReport );

  const root = document.getElementById( 'filtersAndReportCreatorModal' );

  const cancelCallback = () => {
    setExistingReport( null );
    removeFromURLHash( 'creating_report' );
  };

  const handleReportCreatorHashChange = () => {
    const hash = decodeURLHash();

    if ( hash.creating_report ) {
      setShowReportCreator( true );
    } else {
      setShowReportCreator( false );
    }
  };

  const setupForm = async () => {
    const userFetchParams = {
      // eslint-disable-next-line camelcase
      columns: [
        'authentication_provider_id',
        'username',
        'given_name',
        'family_name',
        'api_key',
        'setup_wizard_disabled',
        'email_address',
        'remediation_export_setting',
      ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'username', 'ASC' ] ],
    };

    const usersFetch = await makeRequest( 'POST', '/fe/user/SELECT', userFetchParams );

    const hash = decodeURLHash();
    const reportType = hash.report ? hash.report : hash.page;

    const _validUsers = {};
    const _allUsers = {};

    const name = EMPTY_FIELDS.find( f => f.attribute === 'label' );
    const recipients = EMPTY_FIELDS.find( f => f.attribute === 'email_recipients' );
    const owner = EMPTY_FIELDS.find( f => f.attribute === 'owner' );

    owner.defaultValue = currentUser?.id;

    // eslint-disable-next-line max-len
    name.defaultValue = `${userDisplayName( currentUser )} (${capitalize( reportTypes[reportType] )} Report)`;

    if ( isNotEmpty( usersFetch ) && itemIsArray( usersFetch ) ) {
      usersFetch.map( user => {

        _allUsers[user.id] = userDisplayName( user );

        if ( isNotEmpty( user.email_address ) ) {
          _validUsers[user.id] = userDisplayName( user );
        }
      } );

      recipients.options = _validUsers;
      owner.options = _allUsers;

    } else if ( isNotEmpty( currentUser ) ) {

      const currentUserOption = { [currentUser.id]: userDisplayName( currentUser ) };

      if ( isNotEmpty( currentUser.email_address ) ) {
        _validUsers[currentUser.id] = userDisplayName( currentUser );
      }
      _allUsers[currentUser.id] = userDisplayName( currentUser );

      recipients.options = currentUserOption;
      owner.options = currentUserOption;
    }

    setAllUsers( _allUsers );
    setValidUsers( _validUsers );

    if ( advanced || filterBuilderVariant ) {
      const columnOneAttrs = [
        'label',
        'owner',
        'format',
        'full_xlsx_results',
      ];
      const columnTwoAttrs = [
        'schedule',
        'expiration',
        'email_recipients',
        'no_expiration',
      ];
      const columnOne = [];
      const columnTwo = [];
      setExistingReportRecord( {
        ...existingReport,
        // eslint-disable-next-line camelcase
        full_xlsx_results: existingReport?.display_options?.full_xlsx_results,
        // eslint-disable-next-line camelcase
        no_expiration: existingReport?.display_options?.no_expiration,
        // eslint-disable-next-line camelcase
        dashboard_id: existingReport?.display_options?.dashboard_id,
      } );

      EMPTY_FIELDS.map( f => {
        if ( columnOneAttrs.includes( f.attribute ) ) {

          if ( f.attribute === 'format' ) {
            if ( reportType === 'reporting_dashboard' ) {
              f.defaultValue = 'pdf';
              f.options = { pdf: '.pdf' };
            } else if ( reportType === 'configuration_alerts' ) {
              f.defaultValue = 'xlsx';
              f.options = { xlsx: '.xlsx' };
            } else {
              f.options = { xlsx: '.xlsx', pdf: '.pdf' };
            }
          }

          columnOne.push( f );
        }
        if ( columnTwoAttrs.includes( f.attribute ) ) {
          columnTwo.push( f );
        }
      } );

      setFields( {
        one: {
          fields: columnOne,
        },
        two: {
          fields: columnTwo,
        },
      } );
    } else {
      setFields( EMPTY_FIELDS );
    }
  };

  // sets up the help and options for email recipients and owners
  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) ) {
      setupForm();
    }
  }, [ currentUser, advanced, existingReport ] );

  // sets up the hashchange listener for when the creator should be opened or closed
  React.useEffect( () => {
    window.addEventListener( 'hashchange', handleReportCreatorHashChange );
    return () => window.removeEventListener( 'hashchange', handleReportCreatorHashChange );
  }, [] );

  const shouldReturn = action => decodeURLHash()['report_id']
    || decodeURLHash()['return_to_reports']
    || action === 'UPDATE'
    || isNotEmpty( existingReport );

  const onSave = async() => {

    if ( isValid && isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      let reportID = uuidv4();
      let action = 'INSERT';

      const hash = decodeURLHash();
      const reportType = isNotEmpty( hash.report ) ? hash.report : hash.page;
      const reportCreatorFormValues = getFieldValues( updatedForm.fieldStates, 'report_creator' );

      // need to fork on whether we are creating a report from the dashboard or configuration alerts or not
      if ( hash.page === 'reporting_dashboard' || hash.page === 'configuration_alerts' ) {

        action = 'ADD';

        const newReport = {
          owner: currentUser.id,
          type: hash.page === 'reporting_dashboard' ? 'dashboard' : 'configuration_alerts',
          filters: {},
          // eslint-disable-next-line camelcase
          risk_type: 'direct_risk',
        };

        // for the dashboard, we need to pass along the id of the dashboard that needs to be turned into a pdf
        if ( hash.page === 'reporting_dashboard' ) {
          const dID = localStorage.getItem( 'DSdashboardCurrentLayoutID' );
          // eslint-disable-next-line max-len, camelcase
          const dashboard_id = isNotEmpty( dID ) ? JSON.parse( dID ) : '00000000-0000-0000-0000-000000000001';
          // eslint-disable-next-line camelcase
          newReport.filters.dashboard_id = dashboard_id;
        } else {
          let recordType = hash.group_type ? hash.group_type : hash.report;
          let params = {};
          if ( hash.page === 'configuration_alerts' ) {
            recordType = 'configuration_alerts';
            params = paramsToFilters( hash );
            newReport.filters = params;
          } else {
            params = buildParamsForFastApiEndpoints( recordType );
            if ( isNotEmpty( params ) && isNotEmpty( params.filters ) ) {
              newReport.filters = params.filters;
            }
            if ( isNotEmpty( params ) && isNotEmpty( params.group_filters ) ) {
              // eslint-disable-next-line camelcase
              newReport.group_filters = params.group_filters;
            }
          }
        }

        if (
          newReport.owner === null
          || newReport.owner === undefined
          || newReport.owner === ''
          || newReport.owner === 'null'
        ) {
          newReport.owner = null;
        }

        if ( isNotEmpty( newReport.filters ) ) {
          delete newReport.filters.report_id;
          delete newReport.filters.creating_report;
          delete newReport.filters.return_to_reports;
        }
        if ( isNotEmpty( reportCreatorFormValues ) ) {
          Object.entries( reportCreatorFormValues ).map( ( [ key, value ] ) => {
            if ( key === 'full_xlsx_results' ) {
              // eslint-disable-next-line camelcase
              newReport.filters.full_xlsx_results = value;
            } else if ( key === 'no_expiration' ) {
              // eslint-disable-next-line camelcase
              newReport.filters.no_expiration = value;
              if ( value === true ) {
                newReport.expiration = DOES_NOT_EXPIRE;
              }
            } else {
              newReport[key] = value;
            }
          } );
        }

        if ( isNotEmpty( existingReport ) ) {
          newReport.id = existingReport.id;
          action = 'UPDATE';
        }

        makeRequest( action, '/model/base/exported_report', { record: newReport } ).then( response => {
          if ( response && response.results ) {
            if ( shouldReturn( action ) ) {
              window.location.href = `#.=reporting&page=exports&report_id=${response.results.id}`;
            } else {
              removeFromURLHash( 'creating_report' );
              addFlashMessage( {
                type: 'success',
                // eslint-disable-next-line max-len
                body: <p>Successfully created report. To see progress, edit, or download, visit the <a href={`#.=reporting&page=exports&report_id=${response.results.id}`}>reports page</a>.</p>,
              } );
            }
          }
          removeFromURLHash( 'creating_report' );
          triggerHashRefresh();
        } );
      // risk insight export
      } else {
        const hash = decodeURLHash();
        let rt = reportTypes[reportType];

        if ( rt === 'instance' ) {
          rt = hash.group_type || 'host';
        }
        const params = buildParamsForFastApiEndpoints( rt );

        let _type = reportTypes[reportType];

        if ( _type === 'instance' ) {
          _type = `instance_${ hash.group_type || 'host'}`;
        }

        const newReport = {
          owner: currentUser.id,
          type: _type,
          // eslint-disable-next-line camelcase
          display_options: {},
        };

        if ( _type === 'instance' ) {
          // eslint-disable-next-line camelcase
          newReport.display_options.group_type = hash.group_type || 'host';
        }

        if (
          newReport.owner === null
          || newReport.owner === undefined
          || newReport.owner === ''
          || newReport.owner === 'null'
        ) {
          newReport.owner = null;
        }

        if ( isNotEmpty( params ) ) {
          const { filters, order_by, risk_type, rows, group_filters } = params;
          newReport.filters = filters;
          // eslint-disable-next-line camelcase
          newReport.order_by = order_by;
          // eslint-disable-next-line camelcase
          newReport.risk_type = risk_type;
          // eslint-disable-next-line
          newReport.item_count = rows[1];
          if ( isNotEmpty( group_filters ) ) {
            // eslint-disable-next-line camelcase
            newReport.group_filters = group_filters;
          }
        }

        if ( isNotEmpty( reportCreatorFormValues ) ) {
          Object.entries( reportCreatorFormValues ).map( ( [ key, value ] ) => {
            if ( key === 'full_xlsx_results' ) {
              // eslint-disable-next-line camelcase
              newReport.display_options.full_xlsx_results = value;
              if ( value === true ) {
                // eslint-disable-next-line camelcase
                newReport.item_count = 200_000;
                newReport.format = 'xlsx';
              }
            } else if ( key === 'no_expiration' ) {
              // eslint-disable-next-line camelcase
              newReport.display_options.no_expiration = value;
              if ( value === true ) {
                newReport.expiration = DOES_NOT_EXPIRE;
              }
            } else {
              newReport[key] = value;
            }
          } );
        }

        if ( isNotEmpty( existingReport ) ) {
          reportID = existingReport.id;
          action = 'UPDATE';
        }

        newReport.id = reportID;

        if ( isNotEmpty( newReport.filters ) ) {
          delete newReport.filters.include_risk;
          delete newReport.filters.creating_report;
          delete newReport.filters.return_to_reports;
        }

        makeRequest( 'PUT', `/fe/exported_report/${action}`, [ newReport ] ).then( response => {
          if ( isNotEmpty( response ) && itemIsArray( response ) ) {
            if ( shouldReturn( action ) ) {
              window.location.href = `#.=reporting&page=exports&report_id=${reportID}`;
            } else {
              removeFromURLHash( 'creating_report' );
              addFlashMessage( {
                type: 'success',
                // eslint-disable-next-line max-len
                body: <p>Successfully created report. To see progress, edit, or download, visit the <a href={`#.=reporting&page=exports&report_id=${reportID}`}>reports page</a>.</p>,
              } );
            }
          }
          removeFromURLHash( 'creating_report' );
          triggerHashRefresh();
        } );
      }
    }
  };

  if ( filterBuilderVariant && showReportCreator && isNotEmpty( fields ) ) {
    return <div className="inlineReportCreatorContainer">
      <h2>
            Configure Export
        <a
          className="linkToReports"
          href="#.=reporting&page=exports"
        >
              View all existing exports
          <InlineSVG type="link" version="primary" />
        </a>
      </h2>

      {
        isNotEmpty( fields ) &&
        <Form
          fields={fields}
          setIsValid={setIsValid}
          // eslint-disable-next-line camelcase
          existingRecord={ existingReportRecord }
          recordType={ 'exported_report' }
          onChangeCallback={setUpdatedForm}
          trackUpdates={ false }
        />
      }
      <div className="reportCreatorActions">
        <button
          onClick={ () => cancelReportCreator( cancelCallback ) }
        >
          Cancel
        </button>
        <button
          onClick={ onSave }
          disabled={ !isValid }
          className="submit"
        >
          {
            isNotEmpty( existingReport )
              ? 'Save Changes'
              : 'Create Export'
          }
        </button>
      </div>
    </div>;
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        showReportCreator &&
        <React.Fragment>
          <div className="reportCreatorShade" onClick={ closeReportCreator } />
          <div className="reportCreatorModal">
            <h2>
                  Configure Exported Report Options
              <a
                className="linkToReports"
                href="#.=reporting&page=exports"
              >
                    View all existing exports
                <InlineSVG type="link" version="primary" />
              </a>
            </h2>

            {
              isNotEmpty( fields ) &&
                  <Form
                    fields={fields}
                    setIsValid={setIsValid}
                    // eslint-disable-next-line camelcase
                    existingRecord={ existingReportRecord }
                    recordType={ 'exported_report' }
                    onChangeCallback={setUpdatedForm}
                  />
            }
            {
              (
                isNotEmpty( currentUser )
                && isNotEmpty( licenseInfo )
                && hasFeatureAccess( currentUser, licenseInfo, 'f_configuration_alerts' )
              ) &&
              <ConfigurationAlert
                sourceType="email"
                overrideWith={
                  // eslint-disable-next-line max-len
                  <p>The most recent attempt to send email resulted in an SMTP failure. Please see the <a href="#.=activity&page=configuration_alerts&source=email"> relevant configuration alerts </a> for more information.</p>
                }
              />
            }
            <div className="reportCreatorActions">
              <button
                onClick={ () => cancelReportCreator( cancelCallback ) }
              >
                Cancel
              </button>
              <button
                onClick={ onSave }
                disabled={ !isValid }
                className="submit"
              >
                {
                  isNotEmpty( existingReport )
                    ? 'Save Changes'
                    : 'Create Export'
                }
              </button>
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>,
    root,
  );
};

export default ReportCreator;